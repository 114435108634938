export default {
  breakpoints: ["686px", "970px", "1400px"],
  space: {
    0: 0,
    xxs: 4,
    xs: 8,
    s: 16,
    mm: 24,
    m: 32,
    l: 64,
    xl: 128,
    xxl: 256,
    xxxl: 512,
  },
  fonts: {
    body: "Arial, Helvetica, sans-serif",
    // body: "'Cormorant Garamond', 'Georgia', serif",
    heading: "'Josefin Sans', sans-serif",
    JosefinSans: "'Josefin Sans', sans-serif",
    CormorantGaramond: "'Cormorant Garamond', serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [
    12, // 0
    14, // 1
    16, // 2
    18, // 3
    24, // 4
    32, // 5
    48, // 6
    64, // 7
    96, // 8
  ],
  fontWeights: {
    body: 300,
    heading: 400,
    bold: 400,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    // text: "#000",
    text: "#6C737A",
    background: "#fff",
    primary: "#6C737A",
    secondary: "#9FABAC",
    muted: "#f3f3f3",
    alert: "#b62828",
  },
  sizes: {
    container: 1600,
  },

  layout: {
    container: {
      px: ["s", "m"],
    },
  },

  links: {
    primary: {
      fontFamily: "inherit",
      bg: "transparent",
      border: "none",
      color: "primary",
      cursor: "pointer",
      textDecoration: "none",
      m: 0,
      p: 0,

      "&:hover, &:focus": {
        textDecoration: "underline",
      },

      "&[disabled]": {
        opacity: 0.5,
        cursor: "not-allowed",
        "&:hover, &:focus": {
          textDecoration: "none",
        },
      },
    },
    header: {
      color: "primary",
      fontFamily: "JosefinSans",
      fontWeight: 400,
      fontSize: [3, "14px"],
      py: "xxs",
      textDecoration: "none",
      textTransform: "uppercase",
      whiteSpace: "nowrap",

      "&:hover, &:focus, &.active": {
        color: "secondary",
      },
    },
    footer: {
      color: "inherit",
      textDecoration: "underline",
      "&:hover, &:focus, &.active": {
        color: "text",
      },
    },
  },

  text: {
    default: {
      fontSize: 1,
      m: 0,
      mb: "s",
    },
    heading: {
      color: "primary",
    },
    footer: {
      fontSize: 0,
      color: "#666",
    },
    copyright: {
      color: "#999",
    },
  },

  forms: {
    label: {
      fontSize: 1,
      m: 0,
      mb: "4px",
    },

    input: {
      bg: "#f3f3f3",
      fontFamily: "body",
      boxShadow: "none",
      boxSizing: "border-box",
      borderRadius: "0",
      border: "solid 0px",
      borderBottomWidth: "1px",
      borderColor: "secondary",
      color: "text",
      padding: "xs",
      height: "40px",

      "&:focus": {
        boxShadow: "unset",
        borderColor: "primary",
        outline: "none",
      },
    },
    select: {
      variant: "forms.input",
    },
    textarea: {
      variant: "forms.input",
      height: "auto",
      resize: "none",
    },
    radio: {},
    checkbox: {},
    switch: {},
  },

  buttons: {
    primary: {
      color: "background",
      cursor: "pointer",
      bg: "primary",
      borderRadius: 0,
      py: "xs",
      px: "s",

      "&:hover, &:focus": {
        bg: "secondary",
      },

      "&:focus": {
        outlineColor: "primary",
      },

      "&[disabled]": {
        bg: "secondary",
        cursor: "not-allowed",
      },
    },

    asLink: {
      color: "primary",
      cursor: "pointer",
      bg: "transparent",
      borderRadius: 0,
      borderWidth: 0,
      py: "0",
      px: "0",
      width: "auto",
      height: "auto",
      fontWeight: "700",
      fontFamily: "body",

      "&:hover, &:focus": {},

      "&:focus": {
        outlineColor: "primary",
      },

      "&[disabled]": {
        cursor: "not-allowed",
      },
    },
  },

  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",

      p: {
        variant: "text.default",
      },

      li: {
        variant: "text.default",
        m: 0,
        mb: "xs",
      },
    },

    p: {
      m: 0,
    },
  },
};
